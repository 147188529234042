<div>
    <div class="navbar__group">
        <div class="container">
            <div class="navbar__bg"></div>
            <nav class="navbar navbar-toggleable-md navbar-inverse">
                <button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse"
                    data-target="#navbar" aria-controls="navbar" aria-expanded="false" aria-label="Toggle navigation">
                    <span> </span>
                    <span> </span>
                    <span> </span>
                </button>
                <a class="navbar-brand" href="javascript: void(0)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="134" height="36" viewBox="0 0 134 36" fill="none">
                        <path
                            d="M32.7649 15.748H44.7479V20.3843H32.7649V15.748ZM33.1928 29.4786H27.4153V4.51395H46.2814V9.15024H33.1928V29.4786Z"
                            fill="#202D41" />
                        <path
                            d="M125.817 9.60878C128.194 9.60878 130.108 10.3221 131.559 11.7486C133.033 13.1752 133.77 15.2912 133.77 18.0968V29.0812H128.206V18.9527C128.206 17.431 127.873 16.3017 127.208 15.5646C126.542 14.8038 125.579 14.4234 124.319 14.4234C122.916 14.4234 121.799 14.8632 120.967 15.743C120.134 16.5989 119.718 17.8828 119.718 19.5946V29.0812H114.155V9.89409H119.469V12.1409C120.206 11.3325 121.121 10.7144 122.215 10.2864C123.308 9.83465 124.509 9.60878 125.817 9.60878Z"
                            fill="#202D41" />
                        <path
                            d="M110.079 9.89409V29.0812H104.765V26.8701C103.386 28.5344 101.389 29.3665 98.7735 29.3665C96.9665 29.3665 95.326 28.9623 93.8519 28.154C92.4016 27.3456 91.2603 26.1925 90.4282 24.6946C89.596 23.1967 89.1799 21.4611 89.1799 19.4877C89.1799 17.5143 89.596 15.7786 90.4282 14.2807C91.2603 12.7829 92.4016 11.6297 93.8519 10.8213C95.326 10.013 96.9665 9.60878 98.7735 9.60878C101.222 9.60878 103.136 10.3815 104.515 11.9269V9.89409H110.079ZM99.7364 24.8016C101.139 24.8016 102.304 24.326 103.231 23.375C104.159 22.4002 104.622 21.1044 104.622 19.4877C104.622 17.8709 104.159 16.587 103.231 15.636C102.304 14.6612 101.139 14.1737 99.7364 14.1737C98.3099 14.1737 97.133 14.6612 96.2057 15.636C95.2784 16.587 94.8148 17.8709 94.8148 19.4877C94.8148 21.1044 95.2784 22.4002 96.2057 23.375C97.133 24.326 98.3099 24.8016 99.7364 24.8016Z"
                            fill="#202D41" />
                        <path
                            d="M82.2154 29.2239C80.0518 29.2239 78.3637 28.6532 77.1511 27.512C75.9386 26.347 75.3323 24.7065 75.3323 22.5904V2.47601H80.8958V22.2694C80.8958 23.0778 81.0979 23.7079 81.5021 24.1596C81.9301 24.5876 82.5245 24.8016 83.2853 24.8016C83.5706 24.8016 83.8559 24.7659 84.1412 24.6946C84.4503 24.6232 84.6881 24.54 84.8545 24.4449L85.1042 28.7602C84.1769 29.0693 83.214 29.2239 82.2154 29.2239Z"
                            fill="#202D41" />
                        <path
                            d="M61.6985 9.60878C63.4817 9.60878 65.0985 10.0249 66.5488 10.857C68.0229 11.6654 69.176 12.8185 70.0082 14.3164C70.8403 15.7905 71.2564 17.5143 71.2564 19.4877C71.2564 21.4611 70.8403 23.1967 70.0082 24.6946C69.176 26.1687 68.0229 27.3218 66.5488 28.154C65.0985 28.9623 63.4817 29.3665 61.6985 29.3665C59.2496 29.3665 57.3238 28.5938 55.921 27.0484V36H50.3574V9.89409H55.6713V12.1052C57.0503 10.4409 59.0594 9.60878 61.6985 9.60878ZM60.7356 24.8016C62.1621 24.8016 63.3272 24.326 64.2306 23.375C65.1579 22.4002 65.6215 21.1044 65.6215 19.4877C65.6215 17.8709 65.1579 16.587 64.2306 15.636C63.3272 14.6612 62.1621 14.1737 60.7356 14.1737C59.309 14.1737 58.1321 14.6612 57.2049 15.636C56.3014 16.587 55.8496 17.8709 55.8496 19.4877C55.8496 21.1044 56.3014 22.4002 57.2049 23.375C58.1321 24.326 59.309 24.8016 60.7356 24.8016Z"
                            fill="#202D41" />
                        <path
                            d="M18.8509 8.77251V27.4855C18.8509 29.3023 17.3769 30.0809 15.5668 29.2504L3.28403 23.4885C1.47394 22.632 0 20.4778 0 18.6611V4.12671L7.13695 0L15.5668 3.94503C17.3769 4.80152 18.8509 6.95572 18.8509 8.77251Z"
                            fill="#1A9365" />
                        <path opacity="0.302" fill-rule="evenodd" clip-rule="evenodd"
                            d="M0 4.07481L7.13695 7.42289V0L0 4.07481Z" fill="white" />
                        <path
                            d="M10.0593 10.4335L15.3862 12.9251C15.7482 13.0808 16.0326 12.9251 16.0326 12.5877C16.0326 12.2243 15.7482 11.809 15.3862 11.6533L10.0593 9.16171C9.69729 9.00598 9.41284 9.16171 9.41284 9.49911C9.41284 9.83652 9.72314 10.2777 10.0593 10.4335Z"
                            fill="#D9F0D6" />
                        <path
                            d="M10.0593 6.2808L15.3862 8.7724C15.7482 8.92812 16.0326 8.7724 16.0326 8.43499C16.0326 8.07164 15.7482 7.65637 15.3862 7.50064L10.0593 5.00904C9.69729 4.85332 9.41284 5.00904 9.41284 5.34645C9.41284 5.68385 9.72314 6.09912 10.0593 6.2808Z"
                            fill="#D9F0D6" />
                        <path
                            d="M3.46531 11.4977L15.3861 17.0778C15.7481 17.2335 16.0326 17.0778 16.0326 16.7404C16.0326 16.3771 15.7481 15.9618 15.3861 15.8061L3.46531 10.2C3.10329 10.0442 2.81885 10.2 2.81885 10.5374C2.81885 10.9007 3.10329 11.3419 3.46531 11.4977Z"
                            fill="#D9F0D6" />
                        <path
                            d="M3.46531 15.6503L15.3861 21.2305C15.7481 21.3862 16.0326 21.2305 16.0326 20.8931C16.0326 20.5297 15.7481 20.1145 15.3861 19.9587L3.46531 14.3526C3.10329 14.1969 2.81885 14.3526 2.81885 14.69C2.81885 15.0534 3.10329 15.4946 3.46531 15.6503Z"
                            fill="#D9F0D6" />
                        <path
                            d="M3.46531 19.803L15.3861 25.3832C15.7481 25.5389 16.0326 25.3832 16.0326 25.0458C16.0326 24.6824 15.7481 24.2671 15.3861 24.1114L3.46531 18.5053C3.10329 18.3496 2.81885 18.5053 2.81885 18.8427C2.81885 19.2061 3.10329 19.6473 3.46531 19.803Z"
                            fill="#D9F0D6" />
                    </svg>
                </a>

                <div class="collapse navbar-collapse" id="navbar">
                    <ul class="navbar-nav ml-auto">

                        <li class="nav-item">
                            <a class="nav-link" routerLinkActive="active"
                                [routerLink]="Constant.url_investor_list_page">
                                Nhà đầu tư
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" routerLinkActive="active"
                                [routerLink]="Constant.url_investor_transaction_page">
                                <span>Giao dịch</span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" routerLinkActive="active" [routerLink]="Constant.url_agency_list_page">
                                <span>Cộng tác viên</span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" routerLinkActive="active"
                                [routerLink]="Constant.url_fee_dashboard_page">
                                Quản lý phí
                            </a>
                        </li>
                    </ul>
                </div>

                <ul id="nav-authen">
                    <li>
                        <a class="nav-link btn-border" id="dropdownMenuButton" data-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false">
                            <strong>{{userProfile?.shortName}}</strong>
                            <svg class="nav-authen__icon" xmlns="http://www.w3.org/2000/svg" width="32" height="32"
                                viewBox="0 0 32 32" fill="none">
                                <circle cx="16" cy="16" r="16" fill="url(#paint0_linear_461_1050)" />
                                <path
                                    d="M9.14282 22.2965C9.14282 22.2965 9.14282 21.3616 9.14282 21.2374C9.14282 19.8667 11.1977 18.6201 13.4424 18.1819C13.4424 18.1819 14.129 17.8094 13.9415 17.1228C13.255 16.2512 13.0675 15.3163 13.0675 15.3163C12.9433 15.253 12.7559 15.1288 12.6317 15.0047C12.4442 14.7539 12.1959 14.0064 12.2592 13.4465C12.3201 12.9474 12.4442 13.1348 12.5075 13.0107C12.0084 11.8883 12.2592 10.4543 12.8192 9.3952C14.0024 7.33791 16.4955 7.96118 16.8704 8.70862C19.2394 8.27282 19.1761 12.0125 18.8036 12.9474C18.8036 12.9474 18.9886 13.0107 18.9886 13.8823C18.9277 14.8172 18.1803 15.3772 18.1803 15.3772C18.1803 15.6279 17.8687 16.3754 17.3696 17.062C16.9337 17.9336 17.8054 18.121 17.8054 18.121C20.0501 18.5568 22.105 19.8034 22.105 21.1741C22.105 21.2983 22.105 22.2332 22.105 22.2332C22.105 23.1072 18.7403 24.0421 15.6239 24.0421C12.5708 24.0421 9.14282 23.7305 9.14282 22.2965Z"
                                    fill="white" />
                                <defs>
                                    <linearGradient id="paint0_linear_461_1050" x1="32" y1="16" x2="-1.57518e-06"
                                        y2="16" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#89D5A3" />
                                        <stop offset="1" stop-color="#5CB8C8" />
                                    </linearGradient>
                                </defs>
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="7" viewBox="0 0 10 7"
                                fill="none">
                                <path d="M1.23552 0L0 1.23552L5 6.23552L10 1.23552L8.76448 0L5 3.76448L1.23552 0Z"
                                    fill="#313131" />
                            </svg>
                        </a>

                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <a routerLinkActive="active" [routerLink]="Constant.url_private_info_page">
                                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16"
                                    fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M3.75 3.63636C3.75 1.62806 5.42893 0 7.5 0C9.57107 0 11.25 1.62806 11.25 3.63636C11.25 5.64467 9.57107 7.27273 7.5 7.27273C5.42893 7.27273 3.75 5.64467 3.75 3.63636ZM7.5 1.45455C6.25736 1.45455 5.25 2.43138 5.25 3.63636C5.25 4.84135 6.25736 5.81818 7.5 5.81818C8.74264 5.81818 9.75 4.84135 9.75 3.63636C9.75 2.43138 8.74264 1.45455 7.5 1.45455Z"
                                        fill="#313131" />
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M0 15.2727C0 11.2561 3.35786 8 7.5 8C11.6421 8 15 11.2561 15 15.2727V16H0V15.2727ZM1.54642 14.5455H13.4536C13.0845 11.6754 10.5597 9.45455 7.5 9.45455C4.44029 9.45455 1.91549 11.6754 1.54642 14.5455Z"
                                        fill="#313131" />
                                </svg>
                                <span>Thông tin tài khoản</span>
                            </a>
                            <!-- <a routerLinkActive="active" [routerLink]="Constant.url_change_password_page">
                                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="17" viewBox="0 0 15 17"
                                    fill="none">
                                    <path d="M8.25 10.0455V13.1364H6.75V10.0455H8.25Z" fill="#313131" />
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M12 6.18182V4.63636C12 2.07577 9.98528 0 7.5 0C5.01472 0 3 2.07577 3 4.63636V6.18182H0V17H15V6.18182H12ZM7.5 1.54545C5.84315 1.54545 4.5 2.9293 4.5 4.63636V6.18182H10.5V4.63636C10.5 2.9293 9.15685 1.54545 7.5 1.54545ZM1.5 7.72727V15.4545H13.5V7.72727H1.5Z"
                                        fill="#313131" />
                                </svg>
                                <span>Đổi mật khẩu</span>
                            </a>
                            <a routerLinkActive="active" [routerLink]="Constant.url_investor_list_page">
                                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="17" viewBox="0 0 15 17"
                                    fill="none">
                                    <path d="M6.75 6.18182V13.1364H5.25V6.18182H6.75Z" fill="#313131" />
                                    <path d="M9.75 6.18182V13.1364H8.25V6.18182H9.75Z" fill="#313131" />
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M3.95943 0H11.0406L11.7906 2.31818H15V3.86364H13.5V17H1.5V3.86364H0V2.31818H3.20943L3.95943 0ZM4.79057 2.31818H10.2094L9.95943 1.54545H5.04057L4.79057 2.31818ZM3 3.86364V15.4545H12V3.86364H3Z"
                                        fill="#313131" />
                                </svg>
                                <span>Yêu cầu xóa tài khoản</span>
                            </a> -->
                            <a href="javascript: void(0)" (click)="logout()">
                                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17"
                                    fill="none">
                                    <path d="M0 0H9.16028V1.7H1.66551V15.3H9.16028V17H0V0Z" fill="#313131" />
                                    <path
                                        d="M12.4913 3.89792L17 8.5L12.4913 13.1021L11.3136 11.9L13.8119 9.35H4.16377V7.65H13.8119L11.3136 5.1L12.4913 3.89792Z"
                                        fill="#313131" />
                                </svg>
                                <span>Đăng xuất</span>
                            </a>
                        </div>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
    <div class="page-container">
        <div class="main-content">
            <router-outlet></router-outlet>
            <router-outlet name="popup"></router-outlet>
        </div>
    </div>
</div>