import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders } from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthInterceptor implements HttpInterceptor {
  private readonly SERVER_API_URL: any;

  constructor(private localStorage: LocalStorageService, private sessionStorage: SessionStorageService) {
    this.SERVER_API_URL = environment.urlBackEnd;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let token = this.localStorage.retrieve('TOKEN') || this.sessionStorage.retrieve('TOKEN');
    const object: any = {
      setHeaders: {
      },
      withCredentials: true
    };
    if (request.body) {
      request.body.requestId  = Date.now();
    }
    const params: any = request.params;
    params.map = params.map || new Map();
    if (params.map) {
      params.map.set('requestId', [Date.now()]);
    }
    if (token) {
      object.setHeaders.Authorization = token;
    }
    request = request.clone(object);
    return next.handle(request);
  }
}
