import { ModuleWithProviders } from "@angular/core";
import { Routes, RouterModule, PreloadAllModules } from "@angular/router";
import { Constant } from "./common/constant";
import { NotFoundComponent } from "./common/components/notFound/notFound.component";
import { MaintenanceComponent } from "./common/components/maintenance/maintenance.component";
import { PublicViewImageComponent } from "./common/components/public-view-image/public-view-image";
import { AccountLayoutComponent } from "./common/layouts/account-layout/account-layout.component";

const routes: Routes = [
  {
    path: "",
    redirectTo: "/auth/login",
    pathMatch: "full",
  },
  {
    path: "null",
    redirectTo: "/auth/login",
    pathMatch: "full",
  },
  { path: "view-image", component: PublicViewImageComponent },
  {
    path: "account",
    component: AccountLayoutComponent,
    children: [
      {
        path: Constant.path_profile,
        loadChildren: () =>
          import("./profile-user/profile-user.module").then(
            (m) => m.ProfileUserModule
          ),
      },
      {
        path: Constant.path_operations,
        loadChildren: () =>
          import("./business-operations/business-operations.module").then(
            (m) => m.BusinessOperationsModule
          ),
      },
    ],
  },
  {
    path: Constant.path_account,
    loadChildren: () =>
      import("./profile-user/profile-user.module").then(
        (m) => m.ProfileUserModule
      ),
  },
  {
    path: "auth",
    loadChildren: () =>
      import("./authorization/auth.module").then((m) => m.AuthModule),
  },
  {
    path: "",
    loadChildren: () =>
      import("./dashboard/dashboard.module").then((m) => m.DashboardModule),
  },
  { path: "503", component: MaintenanceComponent },
  { path: "**", component: NotFoundComponent },
];

export const AppRouting = RouterModule.forRoot(routes, {
  preloadingStrategy: PreloadAllModules, // <- comment this line for activate lazy load
  // useHash: true
});
