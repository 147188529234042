import {Injectable, OnInit} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { createRequestOption } from '../common/utils/request-util';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  constructor(
    private http: HttpClient,
  ) {
  }
  getAllResource() {
    return this.http.get(environment.urlBackEnd + 'res/all',  {  observe: 'response' });
  }
}
